.homeTo{
    font-family: 'Quicksand';
    font-weight: bolder;
    font-size: x-large;
    margin-top: 11vh;
}
.homeTo-header{
    /* width: 95%; */
    display: flex;
    justify-content: center;
    align-items: center;
}
.naming{
    justify-content: center;
    align-items: center;
    display: grid;
    text-align: center; /*center all store name*/
}
.paragraphContainer{
    text-align: center;
    margin-bottom: 15vh;
    font-family: 'Quicksand';
    font-weight: bolder;
}
.logo-header {
    margin-bottom: -10vh;
}
.headerP{
    display: inline-block;
    color: black;
    font-family: 'Quicksand';
    font-weight: bolder;
}
.homeTo-footer{
    /* margin-left: 2.5%; */
    color: #ca4949;
}
.row-home-amneties{
    margin-bottom: 8vh;
    display: flex;
    flex: wrap;
}
.lantern-home{
    margin-bottom: 8vh;
    display: flex;
    background-color: #f6f6f6;
}
/* .home-col{
    display: inline-block;
    padding: 3% 3%;
} */

.home-col-container{
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: repeat(auto-fill, minmax(16%, 1fr));
    grid-gap: 5vh;
    margin-top: 3vh;
    margin-right: 4vw;
    margin-left: 4vw;
    margin-bottom: 5vh;
}

.home-col {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    justify-self: center;
    align-self: center;
  }

.home-logo{
    height: auto;
    width: 7vw;
}
.home-coltwo {
    float: left;
}

.row-home-amneties h2{
    font-weight: bolder;
    font-size: x-large;
    margin-top: 20%;
    margin-left: 47%;
    width: 10vw;
}
.paragraph{
    font-family: 'Quicksand';
    font-weight: bolder;
    display: flex;
    align-items: center;
    justify-content: center;
}

.home-logo-two{
    height: auto;
    width: 6vw;
    margin-left: 1vw;
}
.home-coltwo{
    background-color: rgb(245 245 245);
}
.home-coltwo p{
    font-family: 'Quicksand';
    font-size: 20px;
}

.lantern{
    width: 45vw;
    height: auto;
    margin-right: 2vw;
    margin-left: 2vw;
}
.take-btn{
    width: 219.34px;
    height: 54.84px;
    top: 2024.89px;
    left: 692.58px;
    border-radius: 9px;
    border: none;
    font-size: 20px;
    color: white;
    background-color: #ca4949;
}
.header{
    font-weight: 700;
    font-size: 50px;
    line-height: 60px;
    margin: 0;
}
.header-container{
    margin: 5vh 0;
}
.lantern-paragraph{
    font-size: 100%;
}
.header:nth-child(2)
{
    color: #ca4949;
}
.col-home{
    display: inline-block;
    width: 20%;
    padding: 0 2%;
    margin-right: 2vw;
    background-color: #F6F6F6;
    border: #F6F6F6 solid;
    border-radius: 25px;
}
.amnetiesIcon{
    width: auto;
    height: 5vh;
}
.amneties{
    width: auto;
    height: fit-content;
    padding: 2% 5%;
    margin-left: 3vw;
    background: rgba(246, 246, 246, 0.5);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    border-radius: 25px;
    margin-bottom: 4vh;
    margin-top: 11vh;
}
.amneties-paragraph{
    width: 60vw;
    padding: 5% 6%;
    background: rgba(246, 246, 246, 0.5);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    border-radius: 25px;
    margin-bottom: 5vh;
}
.amneties-paragraph h3{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    margin: 0;
    font-size: 5vh;
    text-align: center;
    text-transform: uppercase;
    color: rgba(182, 0, 0, 0.71);

}
.amneties-paragraph p{
    font-family: 'Poppins';
    font-style: normal;
    font-size: 3vh;
    padding: 2% 2%;
    margin: 0;

    text-align: justify;

    color: #333333;

}

.amneties-header{
    font-family: 'Quicksand';
    font-weight: bolder;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
}
.amneties-header h3{
    margin: 0;
    padding: 0;
    padding-bottom: 2vh;
}
.amneties-info {
    /* Add styles for the amneties info container */
    display: flex;
    flex-wrap: wrap;
}
.amneties-info-wrapper{
    width: auto;
    height: 11vh;
    display: flex;
    align-items: center;
}
.amneties-info-wrapper img{
    float: left;
}
.amneties-info-wrapper p{
    font-family: 'Poppins';
    font-size: 3vh;
}
.amnetiesContainer{
    margin-left: 4vw;
    margin-top: 11vh;
}
.map-margin{
    margin-top: 11vh;
}
.mapLocation-container {
    margin-top: 11vh;
    margin-bottom: 11vh;
  }
.photo{
    margin-top: 11vh;
}
.mapPhoto{
   display: flex;
   justify-content: center;
   align-items: center;
}
@media all and (max-width: 300px){
    .naming{
        display: none;
    }

    .naming-bark{
        display: none;
    }

    .naming-skin{
        display: none;
    }

    .naming-two{
        display: none;
    }

    .home-col-container{
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 5px;
        grid-row-gap: 40px;
        margin-top: 3vh;
        align-items: center;
    }
    .homeTo{
        font-size: 15px !important;
    }
    .homeTo-header{
        width:100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .home-logo{
        height: auto;
        width: 70px;
        margin-bottom: 2px;
    }

    /* .home-col-container{
        /* display: grid;
        align-items: center;
        justify-content: center;
        grid-template-columns: repeat(6, 1fr);
        grid-gap: 2px;
        margin-top: 3vh;
        margin-right: 4vw;
        margin-left: 7vw;
        margin-bottom: -2vh;
    } */
    
    .paragraphContainer{
        margin: 0;
        margin-left: 2vw;
        margin-bottom: 5vh;
        padding: 0;
        
    }
    .headerP{
        font-size: 60%;
       margin: 0;
       padding: 0;
    }
    .headerP:nth-child(1){
        margin-left: 3vw;
    }
    .lantern-home{
        display: block;
        padding-bottom: 3vh;
    }
    .lantern{
        width: auto;
        height: 28vh;
        margin-bottom: 3vh;
        
    }

    .row-home-amneties{
        margin-bottom: 8vh;
        display:block;
    }
    .header{
       
        font-weight: 700;
        font-size: 10vw;
        line-height: 5vh;
        margin: 0 10%;
    }
    .header-container{
        margin-bottom: 3vh;
    }
    .header-container p{
        font-size: 2vh;
        margin-left: 12vw;
        padding-right: 28vw;
    }
    .take-btn{
        width: auto;
        height: 6vh;
        margin-left: 20vw;
        font-size: 20px;
    }
    .amneties{
        width: 85vw;
        height: auto;
        padding: 2% 3%;
        margin-left: 7vw;
    }
    /* .amneties-header{
        margin-right: 22vw;
        
    } */
    /* .amneties-info p{
        font-weight: 500;
        font-size: 13px;
        line-height: 26px;
        letter-spacing: 0.02em;
    } */
    .amneties-header h3{
        font-size: 13px;
        font-weight: bold;
    }
    .amneties-info-wrapper{
        height: 8vh;
    }
    .amneties-info img {
        width: 12px !important;
        height: 12px !important;
        margin-right: -20px;
        margin-left: 20px;
    }
    .amneties-info p {
        font-size: 12px;
        line-height: 4.6vh;
        margin-left: 5vw;
        margin-top: 5vw;
    }
    .amneties-paragraph{
        width: 40vw;
        padding: 5% 6%;
        margin-bottom: 5vh;
    }
    .amneties-paragraph h3{
        font-size: 13px;
        line-height: 15px;
        letter-spacing: 0.02em;
        margin: 0;
        padding-top: 3vh;
    }
    .amneties-paragraph{
        width: 82vw;
        padding: 0 0;
        margin-bottom: 5vh;
    }
    .amneties-paragraph p{
        font-size: 10px;
        line-height: 27px;
        margin: 0;
        padding: 2% 2%;
        margin-left: 5px;
        margin-right: 5px;
    }
    .amnetiesContainer{
        margin-left: 4vh;
    }
    .mapPhoto {
        width: 90vw;
        height: auto;
        margin: 0 auto;
        margin-top: -40px;
    }
    .map{
        margin: 0;
        padding: 0;
        width: auto;
        height: 22vh;
     }
 }
@media all and (min-width: 301px) and (max-width: 700px){
    .mapPhoto{
        width: 80vw; /* Adjust the width as desired */
        height: auto;
        margin: 0 auto; /* Center the element horizontally */
    }
    .amneties-info-wrapper {
        width: 100%; /* Change the width to occupy full width on smaller screens */
        height: auto;
      }
    .homeTo{
        font-size: 15px;
    }
    .homeTo-header{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .home-logo{
        height: auto;
        width: 25px;
        margin-left: 2px;
        margin-bottom: 2px;
    }

    .naming{
        display: none;
    }

    .naming-bark{
        display: none;
    }

    .naming-skin{
        display: none;
    }

    .naming-two{
        display: none;
    }

    .home-col-container{
        /* display: grid;
        align-items: center;
        justify-content: center; */
        grid-template-columns: repeat(3, 1fr); 
        grid-gap: 2px;
        grid-row-gap: 20px; /* Add space between rows */
        margin-top: 3vh;
        align-items: center;
    }
    .home-col img{
        height: 60px;
        width: 60px;
    }
    .paragraphContainer{
        align-items: center;
        justify-content: center;
        padding: 0;
        margin: 0; /* Remove left margin for centering */
    }
    .headerP{
        font-size: 13px;
        text-align: center;
        margin: 0; /* Remove left margin for centering */
    }
    .headerP:nth-child(1){
        margin: 0; /* Remove negative margin */
        text-align: center;
    }
    .headerP:nth-child(2){
        margin: 0; /* Remove negative margin */
        text-align: center;
    }
    .lantern-home{
        display: block;
        padding-bottom: 3vh;
    }
    .lantern{
        padding-left: 3vw;
        width: auto;
        height: 30vh;
        margin-bottom: 3vh;
    }
    .row-home-amneties{
        margin-bottom: 8vh;
        display:block;
    }
    .header{
        font-weight: 700;
        font-size: 8vw;
        line-height: 6vh;
        margin: 0 10%;
    }
    .header-container{
        margin-bottom: 3vh;
    }
    .header-container p{
        font-size: 2vh;
        margin-left: 12vw;
        padding-right: 28vw;
    }
    .take-btn{
        width: auto;
        height: 6vh;
        margin-left: 30vw;
        font-size: 20px;
    }
    .amneties{
        width: 82vw;
        height: auto;
        padding: 2% 3%;
        margin-left: auto;
        margin-right: auto;
    }
    .amneties-header{
        font-size: 15px;
        margin-top: 5vw;
    }
    .amneties-info p{
        font-size: 15px;
        line-height: 4.6vh;
        margin-left: 10vw;
    }
    .amneties-info img{
        height: 3.5vh;
        width: auto;
        margin-bottom: 15px;
    }
    .amneties-paragraph{
        width: 40vw;
        padding: 5% 6%;
        margin-bottom: 5vh;
        margin-left: 6vw;
    }
    .amneties-paragraph h3{
        font-weight: 700;
        font-size: 15px;
        line-height: 31px;
        letter-spacing: 0.02em;
    
    }
    .amneties-paragraph{
        width: 82vw;
        padding: 0 0;
        margin-bottom: 5vh;
    }
    .amneties-paragraph h3{
        font-size: 15px;
        line-height: 22px;
        letter-spacing: 0.02em;
        margin: 0;
        padding-top: 3vh;
    
    }
    .amneties-paragraph p{
        font-size: 12px;
        line-height: 27px;
        margin: 0;
        padding: 7% 7%;
    }
    .map{
        margin: 0;
        padding: 0;
        width: auto;
        height: 25vh;
     }
 }
 @media all and (max-width: 500px){
    .row-home-amneties {
        flex-direction: column; /* Stack the elements vertically on smaller screens */
      }

    .amneties-info img{
        width: 20px; /* Adjust the width as needed */
        height: 20px;
        margin-right: -20px;
        margin-left: 20px;
    }
    /* .amneties-info p{
        font-size: 4vw;
    } */

 }
 @media all and (min-width: 501px) and (max-width: 600px){

    .amneties-info img{
        width: 30px; /* Adjust the width as needed */
        height: 30px;
        margin-right: -20px;
        margin-left: 20px;
    }

    .home-logo{
        height: auto;
        width: 50px;
        margin-left: 2px;
        margin-bottom: 2px;
    }

    .home-col-container{
        /* display: grid;
        align-items: center;
        justify-content: center;
        grid-template-columns: repeat(6, 1fr); */
        grid-gap: 10px;
        margin-top: 3vh;
        margin-right: 4vw;
        margin-left: 7vw;
        margin-bottom: 1vh;
    }
    
 }
 
  @media all and (min-width: 701px) and (max-width: 1000px){
    .mapPhoto{
        width: 80vw; /* Adjust the width as desired */
        height: auto;
        margin: 0 auto; /* Center the element horizontally */
    }
    .headerP{
        font-size: 85%;
        margin: 0;
        padding: 0;
    }
    .paragraphContainer{
        margin: 0;
        margin-left: 15vw;
        margin-bottom: 5vh;
        padding: 0;
        
    }

    .home-logo{
        height: auto;
        width: 75px;
        margin-left: 5px;
        margin-bottom: 2px;
    }

    .home-col-container{
        /* display: grid;
        align-items: center;
        justify-content: center;
        grid-template-columns: repeat(6, 1fr); */
        grid-gap: 5px;
        margin-top: 3vh;
        margin-right: 4vw;
        margin-left: 7vw;
        margin-bottom: 1vh;
    }

    .headerP:nth-child(2){
        margin-left: 1vw;
    }
    .lantern-home{
        display: block;
        padding-bottom: 3vh;
    }
    .lantern{
        width: auto;
        height: 40vh;
        margin-bottom: 3vh;
        margin-left: 9vw;
        
    }
    .row-home-amneties{
        margin-bottom: 8vh;
        display:block;
    }
    .header{
        font-weight: 700;
        font-size: 6vw;
        line-height: 6vh;
        margin: 0 10%;
    }
    .header-container{
        margin-bottom: 3vh;
    }
    .header-container p{
        font-size: 2vh;
        margin-left: 10vw;
        padding-right: 18vw;
    }
    .take-btn{
        width: auto;
        height: 6vh;
        margin-left: 37vw;
        font-size: 20px;
    }

    .amneties{
        width: 70vw;
        height: auto;
        padding: 2% 3%;
        margin-left: 15vw;
    }
    /* .amneties-header{
        margin-right: 17vw;
        
    } */
    .amneties-info p{
        font-size: 3vw;
        line-height: 3.7vh;
        letter-spacing: 0.1em;
    }
    .amneties-info img{
        height: 4vh;
        width: auto;
        margin: 0;
        margin-bottom: 1vh;
        padding: 0;
        margin-right: 2vw;
    }
    .amneties-paragraph{
        width: 60vw;
        padding: 5% 6%;
        margin-bottom: 5vh;
        margin-left: 11vw;
    }
    .amneties-paragraph h3{
       
        font-size: 3vw;
        line-height: 31px;
        letter-spacing: 0.02em;
    
    }
    .amneties-paragraph p{
        
        font-size: 2vw;
        line-height: 27px;
        margin: 0;
        padding: 2% 2%;

    }
    .map{
        margin: 0;
        padding: 0;
        width: 80vw;
        height: auto;
     }
    
  }
  
  @media all and (min-width: 1001px){
    .map{
        margin: 0;
        padding: 0;
        width: 80vw;
        height: auto;
     }
    .mapPhoto{
        margin-left: 15vw;
        margin-right: 15vw;
     }
    .amneties-paragraph{
    margin-right: 4vh;
    }
  }
  
  