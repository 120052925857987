.aboutUs-container{
    display: inline-block;
    margin-bottom: 10vh;
}
.row-about-header{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 95%;
    padding: 0;
    margin: 0;
    margin-top: 5vw;
}
.col-about-child{
    display: inline-block;
    padding: 3% 0%;
    vertical-align: middle;

}
.col-about-child h2{
    font-family: 'Quicksand';
    font-weight: bolder;
    font-size: x-large;
}
.col-about-child h3{
    padding: 0%;
    margin: 0;
    font-family: 'Quicksand';
    font-weight: bolder;
    font-size: large;
}
.line{
    padding-left: 5vw;
    
}
.photoAbout{
    height: auto;
    width: 3vw;
    margin-right: 2vw;
}
.col-about:nth-child(2){
    padding-bottom: 3%;
}
.col-about{
    float: left;
    height: auto;
    width: 30%;
    padding: 1% 4%;
    margin-left: 2.5vw;
    background-color: #F6F6F6;
    border: #F6F6F6 solid;
    border-radius: 25px;
}
.aboutParagraph{
    padding-right: 4vw;
    padding-bottom: 2vh;
}
@media screen  and (max-width: 280px) {
    .col-about-child h2{
        font-weight: bolder !important;
        font-size: 12px !important;
        margin-top: 7px;
    }
    .col-about-child h3{
        font-weight: bolder !important;
        font-size: 11px !important;
        margin-top: 7px;
    }
    .aboutParagraph{
        font-size: 11px !important;
        margin-right: 0 !important;
        margin-left: 0 !important;
    }
    p{
        margin: none !important;
    }
}
@media screen and (max-width: 500px){
    .col-about-child h2{
        font-weight: bolder;
        font-size: 15px;
    }
    .col-about-child h3{
        font-weight: bolder;
        font-size: 14px;
    }
    .aboutParagraph {
        font-size: 13px;
    }
    .row-about-header{
        padding: 3vh 0;
        margin-top: 50vw;
    }
    .photoAbout{
        height: auto;
        width: 5vw;
        margin-right: 2vw;
    }
    .row-about{
        margin-bottom: 3vh;
    }
}
@media screen and (min-width: 501px)and (max-width: 1000px){
    .row-about-header{
        padding: 3vh 0;
    }
    .photoAbout{
        height: auto;
        width: 4vw;
        margin-right: 2vw;
    }
    .row-about{
        margin-bottom: 3vh;
    }
}
@media screen  and (max-width: 380px){
   .col-about{
    width: 90vw;
    margin-bottom: 3vh;
    margin-left: 5vw;
   }
   .aboutParagraph{
    
    font-size: small;
    }
    
    
}
@media screen  and (min-width: 381px) and (max-width: 500px){
   
    .col-about{
        width: 90vw;
        margin-bottom: 3vh;
        margin-left: 2.5vh;
    }
       .aboutParagraph{
        padding-right: 15vw;
        
    }
}

@media screen  and (min-width: 501px) and (max-width: 600px){
   
    .col-about{
        width: 90vw;
        margin-bottom: 3vh;
        margin-left: 4vh;
    }
       .aboutParagraph{
        
        
    }
}
 
@media screen  and (min-width: 601px) and (max-width: 1000px){
   
    .col-about{
        width: 90vw;
        margin-bottom: 3vh;
        margin-left: 3vh;
    }
       .aboutParagraph{

        font-size: larger;
        
    }
}
@media screen  and (min-width: 1001px) and (max-width: 1200px){
    .row-about-header{
        padding: 3vh 0;
    }
    .col-about{
        width: 90vw;
        margin-bottom: 3vh;
        margin-left: 5vw;
    }
    .aboutParagraph{
        font-size: large;
        
    }
}
@media screen  and (min-width: 1201px) and (max-width: 1300px){
   
    .row-about:first-child{
        margin-right: 15vw;
    }
    .col-about{
        width: 65vw;
        margin-bottom: 3vh;
        margin-left: 20vw;
    }
    .aboutParagraph{

        font-size: larger;
        
    }
}
 