@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Oswald:wght@500&family=Outfit:wght@100;200;300;400;500;600;700;800;900&family=Reem+Kufi:wght@400;500;600;700&display=swap');
.navbar {
    position: fixed;
    background: white;
    height: 11vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    font-weight: 600;
    font-family: 'Inter', sans-serif;
    top: 0;
    z-index: 999;
    box-shadow: 1px 0 5px 0;
  }
  .navbar-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 80px;
    position: fixed;
    background: white;
  }
  
  .container {
    z-index: 1;
    width: 100%;
    max-width: 1300px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 50px;
    padding-left: 50px;
    position: fixed;
    background: white;
  }

  .navbar-logo {
    color: #fff;
    justify-self: start;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
    height: 100%;
    margin-left: 6vw;
  }
  
  .navbar-icon {
    margin-right: 0.5rem;
  }
  
  .nav-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    text-align: center;
    justify-content: end;
    padding: 0;
    margin-right: 6vw;
  }
  
  .nav-item {
    height: 80px;
    border-bottom: 2px solid transparent;
    color: #3e3e40;
    margin-bottom: auto;
  }
  
  .nav-item:hover {
    color: #3e3e40;
    transform: scale(1.2);
    transition: all 0.3s ease;
  }
  
  .navbar-logo:hover {
    color: #3e3e40;
  }
  .nav-links:hover {
    color: #3e3e40;
  }

  .nav-links {
    color: #3e3e40;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
  }
  
  .fa-bars {
    color: #3e3e40;
  }
  
  .menu-icon {
    display: none;
    color: #3e3e40;
  }
  
  @media screen and (max-width: 960px) {
    .NavbarItems {
      position: relative;
    }
  
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 90vh;
      position: absolute;
      top: 80px;
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
    }
  
    .nav-menu.active {
      background: white;
      left: 0;
      opacity: 1;
      transition: all 0.6s ease;
      z-index: 1;
    }
  
    .nav-links {
      text-align: center;
      padding: 2rem;
      width: 100%;
      display: table;
    }
  
    .nav-links:hover {
      
      transform: scale(1.2);
      transition: all 0.3s ease;
    }
  
    .nav-item:hover {
      border: none;
    }
  
    .nav-item {
      width: 100%;
    }
  
    /* .navbar-logo {
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(25%, 50%);
    }  */
  
    .menu-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
      color: #3e3e40;
    }
  
    .fa-times {
      color: #3e3e40;
      font-size: 2rem;
    }
  
    .nav-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 120px;
    }
  }

  .navbar-logo {
    display: flex;
    align-items: center;
}

.nav_logo-img {
    height: 60px;
    margin-right: 15px;
}

@media screen and (max-width: 768px) {
  .navbar{
    height: 4vh;
    top: 0; /* Adjust this value to match the height of your navbar */
  }
  .navbar-logo{
    font-size: 1.5rem;
  }

  .navbar-logo  .nav_logo-img {
      height: 40px;
      margin-right: 10px;
      margin-top: 20px;
  }
}
@media screen and (min-width: 769px)and (max-width: 1280px){
  .navbar{
    height: 0;
    top: 0 !important; /* Adjust this value to match the height of your navbar */
  }
  .navbar-container {
    top: 0;
  }
  
}